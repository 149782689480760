import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { SubEntity } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, of, forkJoin } from 'rxjs';
import { StatisticsService } from './statisticsService';
import { switchMap, take, map } from 'rxjs/operators';
import { UniModalService } from '@uni-framework/uni-modal/modalService';
import { EditSubEntityAgaZoneModal } from '@app/components/common/modals/editSubEntityAgaZoneModal/editSubEntityAgaZoneModal';

export interface IMuniAGAZone {
    ZoneName: string;
    ZoneID: number;
    MunicipalityNo: string;
    MunicipalityName: string;
}
@Injectable({ providedIn: 'root' })
export class SubEntityService extends BizHttp<SubEntity> {
    constructor(
        protected http: UniHttp,
        private statisticsService: StatisticsService,
        private modalService: UniModalService,
    ) {
        super(http);

        this.relativeURL = SubEntity.RelativeUrl;
        this.entityType = SubEntity.EntityType;
        this.DefaultOrderBy = null;
    }

    public save(subEntity: SubEntity): Observable<SubEntity> {
        return subEntity.ID ? this.Put(subEntity.ID, subEntity) : this.Post(subEntity);
    }

    public saveAll(subEntities: SubEntity[]) {
        return forkJoin(subEntities.map((subEntity) => this.save(subEntity)));
    }

    public getMainOrganization(): Observable<SubEntity[]> {
        return this.GetAll('filter=SuperiorOrganizationID eq 0 or SuperiorOrganizationID eq null', [
            'BusinessRelationInfo',
        ]);
    }

    public getFromEnhetsRegister(orgno: string) {
        return super.GetAction(null, 'sub-entities-from-brreg', 'orgno=' + orgno.replace(/\s+/g, ''));
    }

    public checkZonesAndSaveFromEnhetsregisteret(orgno: string) {
        return this.getMainOrganizationAndFromEnhetsRegister(orgno).pipe(
            switchMap((subEntities) => this.editZonesIfNeeded(subEntities)),
            switchMap((subEntities) => this.saveAll(subEntities)),
        );
    }

    public getMainOrganizationAndFromEnhetsRegister(orgno: string) {
        return forkJoin(this.getFromEnhetsRegister(orgno), this.getMainOrganization()).pipe(
            map((subEntities: [SubEntity[], SubEntity[]]) => [...subEntities[0], ...subEntities[1]]),
        );
    }

    getZonesOnSubEntities(subEntities: SubEntity[]): Observable<IMuniAGAZone[]> {
        if (!subEntities.some((sub) => !!sub.MunicipalityNo)) {
            return of([]);
        }
        return this.statisticsService.GetAllUnwrapped(
            'Select=ZoneName as ZoneName,ID as ZoneID,' +
                'Municipal.MunicipalityNo as MunicipalityNo,Municipal.MunicipalityName as MunicipalityName&' +
                `Model=AGAZone&` +
                `Filter=${subEntities
                    .filter((sub) => !!sub.MunicipalityNo)
                    .map((sub) => `municipalsOnZone.MunicipalityNo eq ${sub.MunicipalityNo}`)
                    .join(' or ')}&` +
                `Join=MunicipalAGAZone.MunicipalityNo eq Municipal.MunicipalityNo as Municipal&` +
                `Expand=municipalsOnZone`,
        );
    }

    editZonesIfNeeded(subEntities: SubEntity[]): Observable<SubEntity[]> {
        return this.getZonesOnSubEntities(subEntities).pipe(
            switchMap((muniZones) => {
                if (
                    subEntities.some(
                        (sub) => muniZones.filter((zone) => zone.MunicipalityNo === sub.MunicipalityNo).length > 1,
                    )
                ) {
                    return <Observable<SubEntity[]>>this.modalService.open(EditSubEntityAgaZoneModal, {
                        data: {
                            subEntities: subEntities,
                            municipalAgaZones: muniZones,
                        },
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                    }).onClose;
                }
                return of(subEntities);
            }),
        );
    }

    checkifAmeldingisSent(fromDate: Date): Observable<boolean> {
        if (!fromDate) {
            return of(false);
        }
        return this.statisticsService
            .GetAll(
                `model=ameldingData` +
                    `&select=id` +
                    `&filter=status ge 2 and year eq ${fromDate.getFullYear()} and period gt ${fromDate.getMonth()}` +
                    `&distinct=true`,
            )
            .pipe(map((res) => res.Data.length > 0));
    }
}
